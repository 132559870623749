import {
  HIDE_TYPE,
  IFolderLNBType,
  ILNBType,
  ILNBTypes,
  ISingleLNBType,
  URL_TYPE,
} from '../../../interface/header/IMenu';
import { AlignType, convertAlignToFlex } from '../../../interface/common/Align';
import { FC, useRef } from 'react';
import classnames from 'classnames/bind';
import styles from '../ViewHeader.module.scss';
import useUrlRouter, { URL_PATH } from '../../../hook/useUrlRouter';
import { checkIsDarkMode } from '../../../util/editor/color.utils';
import useCheckMouseOver from '../../../hook/effect/useCheckMouseOver';
import findVisibleSubMenu from '../../../util/editor/header/findVisibleSubMenu.utils';
import useWindowResize from '../../../hook/useWindowResize';
import Link from 'next/link';
import { HasOption } from '../../../interface/common/hasOption';
import { LANGUAGE } from '../../../constants/language';
import { useUrl } from '../../../hook/useUrl';
import { SOLUTION_TYPE } from '../../../util/solution';

const cx = classnames.bind(styles);

interface IListProps {
  lnbList: ILNBType<ILNBTypes>[];
  isPreview?: boolean;
  isEdit?: boolean;
  style: {
    color: string;
    backgroundColor: string;
    align: AlignType;
  };
}

const MenuList: FC<IListProps & HasOption> = ({ lnbList, isPreview, isEdit, style, options }) => {
  const { color, backgroundColor, align } = style;
  const { handleUrlClick } = useUrlRouter();
  const { ref: dropdownRef, mouseOver: isDropdownMouseOver } = useCheckMouseOver<HTMLUListElement>();
  const dropdownTriggerRef = useRef<HTMLLIElement>(null);
  const { width: windowWidth, scrollBarWidth } = useWindowResize({ debounceMs: 50 });
  const languageType = options && options['languageType'];
  const { buildUrl, getSearchParam } = useUrl();

  const handleClick = (isExternal: boolean, menu: ILNBType<ISingleLNBType>) => {
    const {
      page: { url },
    } = menu;

    if (isEdit) {
      return handleUrlClick({
        url: isExternal
          ? url
          : buildUrl('/editor/setting')
              .withSearchParam('designSn', getSearchParam('designSn'))
              .withSearchParam('pageSn', menu.page.pageSn)
              .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
              .getResult(),
        isExternal,
        isPreview,
      });
    }

    if (isPreview) {
      const pathname = window.location.pathname;
      if (pathname === '/editor/preview') {
        return handleUrlClick({
          url: isExternal
            ? url
            : buildUrl('/editor/preview')
                .withSearchParam('designSn', getSearchParam('designSn'))
                .withSearchParam('pageSn', menu.page.pageSn)
                .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
                .getResult(),
          isExternal,
          isPreview,
        });
      }

      if (pathname === '/template-preview') {
        return handleUrlClick({
          url: isExternal
            ? url
            : buildUrl('/template-preview')
                .withSearchParam('pageSn', menu.page.pageSn)
                .withSearchParam('templateSn', getSearchParam('templateSn'))
                .getResult(),

          isExternal,
          isPreview,
        });
      }
    }

    handleUrlClick({
      url: isExternal
        ? url
        : buildUrl(url)
            .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
            .getResult(),
      isExternal,
      isPreview,
    });
  };

  return (
    <ul
      className={cx('listWrap')}
      style={{
        justifyContent: convertAlignToFlex(align),
      }}
    >
      {lnbList
        .filter(({ hideType }) => hideType === HIDE_TYPE.VISIBLE)
        .map((item, index) => {
          const menu = item as ILNBType<ISingleLNBType>;
          const subMenu = item as ILNBType<IFolderLNBType>;
          const isExternal = menu.page?.urlType === URL_TYPE.EXTERNAL;

          const isSubMenuOverflowInnerWidth = () => {
            if (!dropdownTriggerRef.current) return false;
            const dropdownElement = dropdownTriggerRef.current.querySelector('ul');

            if (!dropdownElement) return false;
            const { left: dropdownTriggerLeft, width: dropdownTriggerWidth } =
              dropdownTriggerRef.current.getBoundingClientRect();

            const dropdownTriggerCenter = dropdownTriggerLeft + dropdownTriggerWidth / 2;
            const dropdownHalfWidth = dropdownElement.getBoundingClientRect().width / 2;

            const dropdownRight = dropdownTriggerCenter + dropdownHalfWidth;
            return dropdownRight > windowWidth - scrollBarWidth;
          };

          switch (menu.formType) {
            case 'SINGLE':
              return (
                <div key={index} className={cx('listItem')} onClick={() => handleClick(isExternal, menu)}>
                  <li className={cx('link', { DARK: checkIsDarkMode(backgroundColor) })} style={{ color }}>
                    <Link
                      href={`${URL_PATH[SOLUTION_TYPE](menu.page.url)}`}
                      passHref
                      onClick={(e) => e.preventDefault()}
                      prefetch={false}
                    >
                      {menu.title}
                    </Link>
                  </li>
                </div>
              );
            case 'FOLDER':
              return (
                <span key={index} className={cx('listItem', menu.formType)} ref={dropdownTriggerRef}>
                  <li
                    className={cx(
                      'link',
                      { 'ignore-hover': isDropdownMouseOver },
                      { DARK: checkIsDarkMode(backgroundColor) }
                    )}
                    style={{ color }}
                    onClick={() => {
                      const firstSubMenu = subMenu.subMenuList
                        .filter(({ hideType }) => hideType === HIDE_TYPE.VISIBLE)
                        .at(0);
                      if (!firstSubMenu) return;
                      const isExternal = firstSubMenu.page?.urlType === URL_TYPE.EXTERNAL;
                      handleClick(isExternal, firstSubMenu);
                    }}
                  >
                    {menu.title}
                    {!!findVisibleSubMenu(subMenu).length && (
                      <ul
                        className={cx('depth-2')}
                        ref={dropdownRef}
                        style={{ right: isSubMenuOverflowInnerWidth() ? '-32px' : 'auto' }}
                      >
                        {subMenu.subMenuList
                          .filter(({ hideType }) => hideType === HIDE_TYPE.VISIBLE)
                          .map((subMenu) => {
                            const isExternal = subMenu.page?.urlType === URL_TYPE.EXTERNAL;

                            return (
                              <li
                                key={subMenu.menuSn}
                                className={cx('subMenu')}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleClick(isExternal, subMenu);
                                }}
                              >
                                <Link
                                  href={`${URL_PATH[SOLUTION_TYPE](subMenu.page.url)}`}
                                  passHref
                                  onClick={(e) => e.preventDefault()}
                                  prefetch={false}
                                >
                                  {subMenu.title}
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    )}
                  </li>
                </span>
              );
          }
        })}
    </ul>
  );
};

export default MenuList;
